$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;
$colordark: #1c3144;
$coloryellow: #d00000;
$colorpink: #ffba08;
$colorblue: #a2aebb;
$colordefault: #3f88c5;

@mixin transition($second) {
    -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
    transition: all $second ease-in-out;
}
